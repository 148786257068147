<template>
	<div class="toast" :data-bs-delay="timeout || 5000" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="toast-header">
			<!--<img class="rounded me-2" alt="...">-->
			<strong class="me-auto">{{ title || 'title' }}</strong>
			<small class="text-muted">{{ this.counter <= 5 ? 'just now' : this.counter + 's ago' }}</small>
			<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
		<div class="toast-body">{{ body || 'body' }}</div>
	</div>
</template>

<script>
export default {
	name: "Toast",
	props: ['title', 'body', 'timeout', 'idx'],
	data() {
		return {
			counter: 0
		};
	},
	mounted() {
		const toastList = [].slice.call(document.querySelectorAll('.toast')).map(toastEl => new bootstrap.Toast(toastEl));
		toastList[this.idx].show();

		const x = setInterval(() => {
			this.counter++;
		}, 1000);

		if (this.counter >= this.timeout) {
			clearInterval(x);
		}
	}
}
</script>

<style scoped>

</style>