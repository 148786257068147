<template>
	<div class="wrap">
		<div id="primary" class="content-area">
			<main id="main" class="site-main">
				<section class="page type-page status-publish hentry" v-if="auth">
					<header class="entry-header mt-4">
						<h1 class="entry-title">Dashboard</h1>
					</header>
					<div class="entry-content">
						<div class="card mb-5">
							<h5 class="card-header p-2">Account information</h5>
							<div class="card-body p-5">
								<h5 class="card-title">{{ user.username }}</h5>
								<div class="card-text my-2">
									<p class="my-3">Since {{ formatDate(user.joined_at) }}</p>
									<p class="my-3">Your email address: <span class="font-monospace">{{ user.email }}</span></p>
									<p v-if="moviesCount > 0">You have Video Quality Indicators measured for
										<b>{{ moviesCount }}</b> multimedia file{{ moviesCount > 1 ? 's' : '' }}.</p>
								</div>
								<button @click="deleteAccountButtonClicked" title="Delete account" class="position-absolute bottom-0 start-0 btn-danger btn-lg btn-block m-2 py-1 px-2">
									<TrashIcon size="20" />
								</button>
								<button title="Edit profile" class="position-absolute bottom-0 end-0 btn-lg btn-block m-2 py-1 px-2"
								        data-bs-toggle="modal" data-bs-target="#editModal">
									<PenIcon size="20"/>
								</button>
							</div>
						</div>
					</div>

					<div class="modal fade" id="editModal" tabindex="-1">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title">Change your account information</h5>
									<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<form @submit.prevent="saveChanges">
									<div class="modal-body align-items-center">
										<div v-if="message" class="mb-3 fs-5 text-center">
											<div :class="`alert alert-${error ? 'danger' : 'success'}`">{{ message }}</div>
										</div>
										<div class="form-outline mb-4">
											<label class="form-label">
												<input type="text" v-model="username" class="form-control form-control-lg"
												       :class="`${usernameError ? 'is-invalid' : ''}`" minlength="5"
												       maxlength="30"/>
												Username
											</label>
										</div>
<!--										<div class="form-outline mb-4">-->
<!--											<label class="form-label">-->
<!--												<input type="email" v-model="email" class="form-control form-control-lg"-->
<!--												       :class="`${emailError ? 'is-invalid' : ''}`"/>-->
<!--												Email address-->
<!--											</label>-->
<!--										</div>-->
										<div class="form-outline mb-4">
											<label class="form-label">
												<input type="password" v-model="newPass" class="form-control form-control-lg"
												       :class="`${newPassError ? 'is-invalid' : ''}`" minlength="6" maxlength="30"/>
												New password
											</label>
										</div>
										<div class="form-outline mb-4">
											<label class="form-label">
												<input type="password" v-model="newPass2" class="form-control form-control-lg"
												       :class="`${newPassError ? 'is-invalid' : ''}`" minlength="6" maxlength="30"/>
												Confirm new password
											</label>
										</div>
										<div class="form-outline mb-4">
											<label class="form-label">
												<input type="password" v-model="pass" class="form-control form-control-lg"
												       :class="`${passError ? 'is-invalid' : ''}`" minlength="6" maxlength="30"
												       required/>
												Current password (required)
											</label>
										</div>
									</div>
									<div class="modal-footer">
										<button type="button" class="btn-lg btn-primary" data-bs-dismiss="modal">Close</button>
										<button type="submit" class="btn-lg btn-block">Save changes</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</main>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import Nav from "@/components/Nav";
import PenIcon from "@/components/icons/PenIcon";
import TrashIcon from "@/components/icons/TrashIcon";

export default {
	name: "Dashboard",
	components: {TrashIcon, Nav, PenIcon},
	data: () => ({
		moviesCount: 0,
		username: '',
		usernameError: false,
		email: '',
		emailError: false,
		newPass: '',
		newPass2: '',
		newPassError: false,
		pass: '',
		confirmPassword: '',
		passError: false,
		message: '',
		error: false
	}),
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		},
		user() {
			return this.$store.getters.user;
		}
	},
	methods: {
		async deleteAccountButtonClicked() {
			if (confirm('Are you sure to delete this account? All data will be removed')) {
				const password = prompt('Please provide password to confirm');
				try {
					const {data} = await axios.put('/user/delete-account', {password});
					alert(data.message);
					if (data.success) {
						location.reload();
					}
				} catch (e) {
					alert(e?.response.data.message);
				}
			}
		},
		async saveChanges() {
			this.error = false;
			this.usernameError = false;
			this.emailError = false;
			this.newPassError = false;
			this.passError = false;

			let toSaveCount = 0;
			let savedCount = 0;

			if (this.pass.length === 0) {
				this.passError = true;
				this.error = true;
				this.message = 'Insert current password!';
				return;
			}

			if (this.user.username !== this.username) {
				toSaveCount++;
				if (this.username.length < 5 || this.username.length > 30) {
					this.usernameError = true;
					this.error = true;
					this.message = 'Username must be between 5 and 30 characters long!';
					return;
				}

				try {
					const {data} = await axios.put('/user/username', {
						newUsername: this.username,
						password: this.pass
					});
					if (data.success) {
						savedCount++;
						this.usernameError = false;
					} else {
						if (data.message.includes('password')) {
							this.passError = true;
						}
						this.error = true;
						this.message = data.message;
					}
				} catch (e) {
					this.usernameError = true;
					this.error = true;
					this.message = e?.response.data.message;
					return;
				}
			}

			if (this.user.email !== this.email) {
				toSaveCount++;
				if (!/\S+@\S+\.\S+/.test(this.email)) {
					this.error = true;
					this.message = 'Insert correct email address!';
					return;
				}

				try {
					const {data} = await axios.put('/user/email', {
						newEmail: this.email,
						password: this.pass
					});
					if (data.success) {
						savedCount++;
						this.emailError = false;
					} else {
						if (data.message.includes('password')) {
							this.passError = true;
						}
						this.error = true;
						this.message = data.message;
					}
				} catch (e) {
					this.emailError = true;
					this.error = true;
					this.message = e?.response.data.message;
					return;
				}
			}

			if (this.newPass.length && this.newPass2.length) {
				toSaveCount++;
				if (this.newPass !== this.newPass2) {
					this.newPassError = true;
					this.error = true;
					this.message = 'Passwords do not match!';
					return;
				}
				if (this.newPass.length < 6 || this.newPass.length > 30) {
					this.newPassError = true;
					this.error = true;
					this.message = 'Password must be between 6 and 30 characters long!';
					return;
				}

				try {
					const {data} = await axios.put('/user/password', {
						newPassword: this.newPass,
						password: this.pass
					});
					if (data.success) {
						savedCount++;
						this.newPassError = false;
					} else {
						if (data.message.includes('password')) {
							this.passError = true;
						}
						this.error = true;
						this.message = data.message;
					}
				} catch (e) {
					this.newPassError = true;
					this.error = true;
					this.message = e?.response.data.message;
					return;
				}
			}

			if (savedCount !== toSaveCount || savedCount === 0) {
				return;
			}

			this.error = false;
			this.newPass = '';
			this.newPass2 = '';
			this.pass = '';
			this.message = 'Changes has been saved!';

			setTimeout(() => {
				this.message = '';
			}, 8000);

			try {
				await this.$store.dispatch('attempt');
			} catch (e) {
			}

			this.username = this.user.username;
			this.email = this.user.email;
		},
		async setMoviesCount() {
			if (!this.auth) {
				return;
			}

			let count = 0;
			try {
				const {data} = await axios.get('/vqis/videos/');
				count = data.movies.reduce((counter, movie) => movie.is_processed ? ++counter : counter, 0);
			} catch (e) {
			}
			this.moviesCount = count;
		},
		formatDate(date) {
			let d = new Date(date),
				month = d.toLocaleString('default', {month: 'short'}),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [day, month, year].join(' ');
		}
	},
	async beforeMount() {
		this.$store.dispatch('attempt')
			.then(async _ => {
				if (!this.auth) {
					await router.push('/');
				}
				await this.setMoviesCount();
				this.username = this.user.username;
				this.email = this.user.email;
			})
			.catch(async error => {
				if (!this.auth) {
					await router.push('/');
				}
			});
	}
};
</script>

<style scoped>
</style>
