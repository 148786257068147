import axios from 'axios';
import APP_CONFIG from '@/config';

axios.defaults.baseURL = APP_CONFIG.apiUrl;

let refresh = false;

axios.interceptors.response.use(res => res, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        const {status, data} = await axios.post('/auth/refresh', {}, { withCredentials: true });

        if (status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
            return axios(error.config);
        }
    }

    refresh = false;
    return Promise.reject(error);
});
