<template>
	<div id="page" class="site">
		<header id="masthead" class="site-header">
			<div class="custom-header mb-3 pb-5">
				<div class="custom-header-media">
					<div class="wp-custom-header">
						<img src="@/assets/images/cropped-tlo-2.png" width="2000" height="1000" alt="" sizes="100vw" />
					</div>
				</div>

				<div class="site-branding">
					<div class="wrap">

						<a href="https://qoe.agh.edu.pl/" class="custom-logo-link" rel="home"><img width="250" height="250" src="@/assets/images/cropped-logoqoe_crop_square-1.png" class="custom-logo" alt="AGH Video Quality of Experience (QoE) Team" sizes="100vw" /></a>
						<div class="site-branding-text">
							<p class="site-title"><a href="https://qoe.agh.edu.pl/" rel="home">AGH Video Quality of Experience (QoE) Team</a></p>

							<p class="site-description">Welcome and thank you for visiting our website, which is created to present our team and our current research.</p>
						</div><!-- .site-branding-text -->


					</div><!-- .wrap -->
				</div><!-- .site-branding -->

			</div><!-- .custom-header -->

			<div class="navigation-top" v-if="auth">
				<div class="wrap">
					<nav id="site-navigation" class="main-navigation" aria-label="Top Menu">
						<button class="menu-toggle" aria-controls="top-menu" aria-expanded="false">
							<svg class="icon icon-bars" aria-hidden="true" role="img"> <use href="#icon-bars" xlink:href="#icon-bars"></use> </svg><svg class="icon icon-close" aria-hidden="true" role="img"> <use href="#icon-close" xlink:href="#icon-close"></use> </svg>Menu	</button>

						<div class="menu-menu-glowne-container">
							<ul class="menu d-flex justify-content-center">
								<li class="menu-item menu-item-type-custom menu-item-object-custom mx-2" :class="{'current-menu-item': currentRouteName === 'dashboard'}"><router-link to="/dashboard">Dashboard</router-link></li>
								<li class="menu-item menu-item-type-post_type menu-item-object-page mx-2" :class="{'disabled current-menu-item': currentRouteName === 'vqis' || currentRouteName === ''}"><router-link to="/vqis">VQIs</router-link></li>
<!--								<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">-->
<!--									<a href="https://qoe.agh.edu.pl/video-library/">-->
<!--										VQIs-->
<!--										<svg class="icon icon-angle-down" aria-hidden="true" role="img"> <use href="#icon-angle-down" xlink:href="#icon-angle-down"></use> </svg></a>-->
<!--									<ul class="sub-menu">-->
<!--										<li class="menu-item menu-item-type-post_type menu-item-object-page page_item" :class="{'disabled current-menu-item current_page_item': currentRouteName === 'vqis'}"><router-link to="/vqis">Uploading</router-link></li>-->
<!--										<li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="#">Results</a></li>-->
<!--									</ul>-->
<!--								</li>-->
							<li class="menu-item menu-item-type-post_type menu-item-object-page mx-2"><a href="#" @click="logout">Logout</a></li>
						</ul></div>
					</nav>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
import axios from 'axios';
import { computed } from 'vue';

export default {
	name: "Nav",
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		},
		currentRouteName() {
			return this.$route.path.slice(1)
		}
	},
	methods: {
		logout() {
			this.$store.dispatch("logout")
				.then(async _ => {
					await this.$router.push('/login');
				})
				.catch(error => {});
		}
	},

};
</script>
