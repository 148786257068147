<template>
	<div class="wrap">
		<div id="primary" class="content-area">
			<main id="main" class="site-main">
				<section class="page type-page status-publish entry">
					<section class="error-404 not-found m-5 py-5 px-2">
						<header class="page-header">
							<h1 class="page-title">Oops! That page can&rsquo;t be found.</h1>
						</header>
						<div class="page-content">
							<p>It looks like nothing was found at this location. Maybe try a search?</p>
							<form role="search" method="get" class="search-form" :action="hostName">
								<label for="search-form-1">
									<span class="screen-reader-text">Search for:</span>
								</label>
								<input type="search" id="search-form-1" class="search-field" placeholder="Search &hellip;" value="" name="s" />
								<button type="submit" class="search-submit"><svg class="icon icon-search" aria-hidden="true" role="img"> <use href="#icon-search" xlink:href="#icon-search"></use> </svg><span class="screen-reader-text">Search</span></button>
							</form>
						</div>
					</section>
				</section>
			</main>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageNotFound",
	data () {
		return {
			hostName: ''
		};
	},
	created() {
		this.hostName = `${window.location.origin}/`;
	}
}
</script>
