<template>
	<form @submit.prevent="sendFile" enctype="multipart/form-data">
<!--		<h1 class="h3 mb-3 fw-normal">Upload file</h1>-->
		<div v-if="message" class="mb-3 fs-5 text-center">
			<div :class="`alert alert-${error ? 'danger' : 'success'}`">{{message}}</div>
		</div>

		<div v-if="loginInfo" class="mb-3 fs-5 text-center">
			<div class="alert alert-warning">Please <router-link to="/login" class="link-primary">sign in</router-link> before counting Video Quality Indicators</div>
		</div>

		<div v-if="uploading || progress > 0" class="mb-3">
			<div class="progress" style="height: 25px;">
				<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="`width: ${progress}%`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
					{{ progress }}%
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="w-100">
				<div @dragover="dragover" @dragleave="dragleave" @drop="drop"
				     id="droparea" class="my-3 bg-dark bg-gradient text-light">
					<div class="p-2">
						<div class="" style="font-size: 5rem;">
							<CloudUploadIcon size="50" />
						</div>
						<div v-if="file.name !== undefined" class="py-3">{{file.name}}</div>
						<div v-else class="py-3"><b>Click</b> to choose a multimedia file or <b>drag</b> it here!</div>
					</div>
				</div>
				<input @change="selectFile" type="file" ref="file" :accept="allowedFileTypes.map(e => `.${e}`).join(', ')" hidden />
			</label>
		</div>

		<div class="field">
			<button class="w-100 btn-lg" :disabled="uploading">Upload</button>
		</div>

	</form>
</template>

<script>
import axios from 'axios';
import APP_CONFIG from "@/config";
import CloudUploadIcon from '@/components/icons/CloudUploadIcon';
import {getFileExtension} from "@/utils";
export default {
	name: "FileUploader",
	components: {CloudUploadIcon},
	data() {
		return {
			allowedFileTypes: APP_CONFIG.allowedFileTypes,
			file: '',
			description: '',
			uploading: false,
			progress: 0,
			message: '',
			loginInfo: false,
			error: false
		}
	},
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		}
	},
	methods: {
		loginPopup() {
			if (this.auth) return false;

			this.loginInfo = true;
			return true;
		},
		dragover(e) {
			e.preventDefault();
			document.querySelector('#droparea').classList.remove('bg-dark');
			document.querySelector('#droparea').classList.add('bg-secondary');
		},
		dragleave(e) {
			document.querySelector('#droparea').classList.add('bg-dark');
			document.querySelector('#droparea').classList.remove('bg-secondary');
		},
		drop(e) {
			e.preventDefault();

			document.querySelector('#droparea').classList.add('bg-dark');
			document.querySelector('#droparea').classList.remove('bg-secondary');

			if (this.loginPopup()) return;
			if (this.uploading) return;

			if (e.dataTransfer.files.length === 0) return;
			if (!APP_CONFIG.allowedFileTypes.includes(getFileExtension(e.dataTransfer.files[0].name))) {
				this.error = true;
				this.message = `Please select file with one of the following extensions: ${APP_CONFIG.allowedFileTypes.join(', ')}`;
				return;
			}

			const fileSizeInGB = e.dataTransfer.files[0].size / 1_000_000_000;
			if (fileSizeInGB > APP_CONFIG.maxFileSizeInGB) {
				this.error = true;
				this.message = `Maximum file size is ${APP_CONFIG.maxFileSizeInGB}GB.`;
				return;
			}

			this.$refs.file.files = e.dataTransfer.files;
			this.file = this.$refs.file.files[0];
			this.error = false;
			this.message = '';
			this.uploading = false;
			this.progress = 0;
		},
		selectFile() {
			if (this.loginPopup()) return;
			if (this.uploading) return;

			if (this.$refs.file.files.length === 0) return;
			if (!APP_CONFIG.allowedFileTypes.includes(getFileExtension(this.$refs.file.files[0].name))) {
				this.error = true;
				this.message = `Please select file with one of the following extensions: ${APP_CONFIG.allowedFileTypes.join(', ')}`;
				return;
			}

			const fileSizeInGB = this.$refs.file.files[0].size / 1_000_000_000;
			if (fileSizeInGB > APP_CONFIG.maxFileSizeInGB) {
				this.error = true;
				this.message = `Maximum file size is ${APP_CONFIG.maxFileSizeInGB}GB.`;
				return;
			}

			this.file = this.$refs.file.files[0];
			this.error = false;
			this.message = '';
			this.uploading = false;
			this.progress = 0;
		},
		async sendFile() {
			const formData = new FormData();
			if (this.uploading || !this.file) {
				return;
			}
			formData.append('file', this.file);
			formData.append('description', this.description === '' ? this.file.name.substring(0, 55) : this.description);

			formData.append('colourfulness', ''+false);
			formData.append('blurAmount', ''+false);
			formData.append('ugc', ''+true);

			this.uploading = true;
			this.progress = 0;
			try {
				const {data} = await axios.post('/vqis/upload', formData, {
					onUploadProgress: progressEvent => {
						const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						this.progress = progress === 100 ? 99 : progress;
					}
				});
				const filename = data.filename;
				this.$emit('uploaded', data.message || `File has ${data.success ? '' : 'not'} been uploaded!`);
				this.progress = 100;
				this.file = '';
				this.$refs.file.value = '';
				this.description = '';
				this.error = !data.success;
				this.uploading = false;

				setTimeout(() => {
					this.progress = 0;
				}, 6000);

				const x = setInterval(async () => {
					try {
						const {data} = await axios.get(`/vqis/videos/${filename.split('.').slice(0, -1).join('.')}`);
						if (data.success) {
							if (data.movie.is_processed) {
								this.$emit('processingEnded', data);
								clearInterval(x);
							}
						}
					} catch (e) {}
				}, 5000);
			} catch (e) {
				this.message = e.response.data.message || 'Something went wrong!';
				this.error = true;
				this.file = '';
				this.$refs.file.value = null;

				setTimeout(() => {
					this.message = '';
					this.progress = 0;
					this.uploading = false;
				}, 8000);
			}
		}
	}
}
</script>
<style>
#droparea {
	text-align: center;
	border: 1px solid #000;
	border-radius: 7px;
}
</style>
