<template>
	<main class="vh-100">
		<div class="container-fluid">
			<div class="row" style="display:flex;align-items: center;">
				<div class="col-sm-6 text-black mt-3">
					<div class="px-5 ms-xl-4">
						<a href="#"><img class=" mt-xl-4" src="@/assets/images/cropped-logoqoe_crop_square-1.png" width="50" alt="logo"/></a>
						<div class="h1 fw-bold mb-0">AGH Video Quality of Experience (QoE) Team</div>
					</div>
					<div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
						<form @submit.prevent="register">
							<h3 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Sign up</h3>
							<div v-if="message" class="mb-3 fs-5 text-center">
								<div :class="`alert alert-${error ? 'danger' : 'success'}`">{{message}}</div>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input @keyup="validateUsername" type="text" v-model="username" :class="`form-control form-control-lg ${this.invalidInputs.has('username') && 'is-invalid'} ${this.validInputs.has('username') && 'is-valid'}`" minlength="5" maxlength="30" required />
									Username
								</label>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input @keyup="validateEmail" type="email" v-model="email" :class="`form-control form-control-lg ${this.invalidInputs.has('email') && 'is-invalid'} ${this.validInputs.has('email') && 'is-valid'}`" required />
									Email address
								</label>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input @keyup="validatePasswords" type="password" v-model="pass" :class="`form-control form-control-lg ${this.invalidInputs.has('pass') && 'is-invalid'} ${this.validInputs.has('pass') && 'is-valid'}`" minlength="6" maxlength="30" required />
									Password
								</label>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input @keyup="validatePasswords" type="password" v-model="pass2" :class="`form-control form-control-lg ${this.invalidInputs.has('pass2') && 'is-invalid'} ${this.validInputs.has('pass2') && 'is-valid'}`" minlength="6" maxlength="30" required />
									Confirm password
								</label>
							</div>

							<div class="pt-1 mb-4">
								<button class="w-100 btn-lg btn-block" type="submit">Register</button>
							</div>

							<p>
								Have an account?
								<router-link to="/login" :class="`${!this.error && this.message ? 'link-success' : 'link-secondary'}`">Login here</router-link>
							</p>
						</form>
					</div>
				</div>
				<div class="col-sm-6 px-0 d-none d-sm-block">
					<img src="@/assets/images/cropped-back-cut.png"
					     alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import router from "@/router";
import axios from "axios";
export default {
	name: "Register",
	data: () => ({
		username: '',
		email: '',
		pass: '',
		pass2: '',
		message: '',
		error: false,
		invalidInputs: new Set(),
		validInputs: new Set()
	}),
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		}
	},
	beforeMount() {
		this.$store.dispatch('attempt')
			.then(async _ => {
				if (this.auth) {
					await router.push('/dashboard');
				}
			})
			.catch(error => {});
	},
	methods: {
		validateUsername() {
			if (this.username.length >= 5 && this.username.length <= 30) {
				this.message = '';
				this.error = false;
				this.invalidInputs.delete('username');
				this.validInputs.add('username');
				return;
			}

			this.validInputs.delete('username');
			this.invalidInputs.add('username');
		},
		validateEmail() {
			if (/\S+@\S+\.\S+/.test(this.email)) {
				this.message = '';
				this.error = false;
				this.invalidInputs.delete('email');
				this.validInputs.add('email');
				return;
			}

			this.validInputs.delete('email');
			this.invalidInputs.add('email');
		},
		validatePasswords() {
			if (this.pass.length < 6) return;
			if (this.pass === this.pass2) {
				this.message = '';
				this.error = false;
				this.invalidInputs.delete('pass');
				this.invalidInputs.delete('pass2');
				this.validInputs.add('pass').add('pass2');
				return;
			}

			this.message = 'Passwords do not match!';
			this.error = true;
			this.validInputs.delete('pass');
			this.validInputs.delete('pass2');
			this.invalidInputs.add('pass').add('pass2');
		},
		register() {
			if (this.pass !== this.pass2) {
				this.message = 'Passwords do not match!';
				this.error = true;
				this.invalidInputs.add('pass').add('pass2');
				return;
			}
			if (this.invalidInputs.size > 0) {
				return;
			}
			this.invalidInputs.delete('pass')
			this.invalidInputs.delete('pass2');
			this.$store.dispatch("register", {
				username: this.username,
				email: this.email,
				password: this.pass
			})
				.then(res => {
					this.error = !res.success;
					this.message = res.message;

					this.username = '';
					this.email = '';
					this.pass = '';
					this.pass2 = '';

					this.validInputs.clear();

					// this.$router.push('/login');
				})
				.catch(error => {
					this.message = error?.response.data.message || 'Error';
					if (this.message.toLowerCase().includes('username')) {
						this.validInputs.delete('username');
						this.invalidInputs.add('username');
					}
					if (this.message.toLowerCase().includes('email')) {
						this.validInputs.delete('email');
						this.invalidInputs.add('email');
					}
					if (this.message.toLowerCase().includes('password')) {
						this.validInputs.delete('pass');
						this.validInputs.delete('pass2');
						this.invalidInputs.add('pass').add('pass2');
					}
					this.error = true;
				});
		}
	}
};
</script>
