export const formatDate = (date) => {
	let d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = '' + d.getFullYear(),
		hr = '' + d.getHours(),
		min = '' + d.getMinutes(),
		sec = '' + d.getSeconds();

	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;
	if (hr.length < 2)
		hr = '0' + hr;
	if (min.length < 2)
		min = '0' + min;
	if (sec.length < 2)
		sec = '0' + sec;

	return [year, month, day].join('-') + ' ' + [hr, min, sec].join(':');
}

export const getFileExtension = (filename) => {
	const extensions = filename.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
	return extensions != null && extensions.length > 0 ? extensions.pop().toLowerCase() : '';
}
