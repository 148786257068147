import axios from "axios";
import router from "@/router";

export default {
	state: {
		user: null
	},
	getters: {
		authenticated(state) {
			return state.user !== null;
		},
		user(state) {
			return state.user;
		}
	},
	mutations: {
		SET_USER (state, user) {
			state.user = user;
		}
	},
	actions: {
		async logout(_) {
			return new Promise((resolve, reject) => {
				axios.get('/auth/logout', {withCredentials: true})
					.then(res => {
						axios.defaults.headers.common['Authorization'] = '';
						this.commit('SET_USER', null);
						resolve(res);
					})
					.catch(error => reject(error));
			});
		},
		login (_, payload) {
			return new Promise((resolve, reject) => {
				axios.post('/auth/login', payload, { withCredentials: true })
					.then(({data, status}) => {
						if (status === 200) {
							axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
							this.dispatch('attempt')
								.then(_ => resolve(data))
								.catch(error => reject(error));
						}
					})
					.catch(error => reject(error));
			});
		},
		activateAccount (_, payload) {
			return new Promise((resolve, reject) => {
				axios.get(`/auth/activate-account/${payload.token}`)
					.then(({data, status}) => {
						if (status === 200) {
							resolve(data);
						}
					})
					.catch(error => reject(error));
			});
		},
		attempt ({commit}) {
			return new Promise((resolve, reject) => {
				axios.get('/auth/user')
					.then(res => {
						this.commit('SET_USER', res.data.user)
						resolve(res);
					})
					.catch(error => reject(error));
			});
		},
		register: (_, payload) => {
			return new Promise((resolve, reject) => {
				axios.post('/auth/register', payload)
					.then(({data, status}) => {
						if (status === 201) {
							resolve(data);
						}
					})
					.catch(error => reject(error));
			});
		}
	}
};
