<template>
	<div class="position-relative" aria-live="polite" aria-atomic="true">
		<div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 100;">
			<Toast v-for="toast in toasts" :key="toast.id" :idx="toast.id" :title="toast.title" :body="toast.body" :timeout="toast.timeout"></Toast>
		</div>
	</div>
	<div class="wrap">
		<div id="primary" class="content-area">
			<main id="main" class="site-main">
				<section class="page type-page status-publish entry">
					<header class="entry-header mt-4">
						<h1 class="entry-title">Video Quality Indicators</h1>
					</header>
					<div class="entry-content">
						<h2>Upload multimedia file to count VQIs</h2>
						<div>
							<div class="card card-body">
								<file-uploader @uploaded="uploaded" @processingEnded="processingEnded" />
							</div>
						</div>

						<h2 v-if="auth && movies.length > 0">
							All of your results
<!--							<button @click="displayAllMovies" title="refresh" class="btn btn-light">-->
<!--								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">-->
<!--									<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>-->
<!--									<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>-->
<!--								</svg>-->
<!--							</button>-->
						</h2>
						<div v-if="auth">
							<div v-for="movie in movies">
								<MovieRecord @deleted="deleteRecord" :id="movie.id" :uploaded_at="movie.uploaded_at" :name="movie.filename" :is_processed="movie.is_processed" :description="movie.description" />
							</div>
						</div>
					</div>
				</section>
			</main>
		</div>
	</div>
<!--	<button class="w-100 btn btn-lg btn-warning" @click="displayAllMovies">Refresh</button>-->
</template>

<script>
import router from "@/router";
import axios from "axios";
import FileUploader from "@/components/FileUploader";
import MovieRecord from "@/components/MovieRecord";
import {formatDate} from "@/utils";
import Toast from "@/components/Toast";

export default {
	name: "VQIs",
	components: {Toast, FileUploader,MovieRecord},
	data() {
		return {
			movies: [],
			toasts: [],
			count: 0
		}
	},
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		},
		user() {
			return this.$store.getters.user;
		}
	},
	async beforeMount() {
		this.$store.dispatch('attempt')
			.then(async _ => {
				if (!this.auth) {
					await router.push('/');
				}
				await this.displayAllMovies();
			})
			.catch(async error => {
				if (!this.auth) {
					await router.push('/');
				}
			});
	},
	methods: {
		formatDate,
		addToast(props) {
			this.toasts.push({
				'type': Toast,
				title: props.title,
				body: props.body,
				timeout: props.timeout || 5000,
				id: this.count++
			});
		},
		async uploaded(msg) {
			this.addToast({
				title: 'File upload feedback',
				body: msg,
				timeout: 20000
			});

			await this.displayAllMovies();
		},
		async processingEnded(data) {
			const fileName = data.movie.description === '' ? this.formatDate(data.movie.uploaded_at) : data.movie.description;
			this.addToast({
				title: 'VQIs are ready!',
				body: `Results for ${fileName} file are available`,
				timeout: 20000
			});
			await this.displayAllMovies();
		},
		async displayAllMovies() {
			const {data} = await axios.get('/vqis/videos');

			data.movies.forEach(movie => {
				if (movie.is_processed) {
					return;
				}
				const x = setInterval(async () => {
					try {
						const {data} = await axios.get(`/vqis/videos/${movie.filename}`);
						if (data.success) {
							if (data.movie.is_processed) {
								await this.displayAllMovies();
								clearInterval(x);
							}
						}
					} catch (e) {}
				}, 5000);
			});

			this.movies = data.movies;
		},
		deleteRecord(id) {
			const index = this.movies.findIndex(f => f.id === id);
			this.movies.splice(index, 1);
		}
	}
};
</script>

<style scoped>
</style>
