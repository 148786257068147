<template>
	<div class="accordion m-2">
		<div class="accordion-item">
			<h2 class="accordion-header">
				<div class="container">
					<div class="row">
						<div class="col-sm-11 mb-1 mt-2">
							<button class="accordion-button collapsed bg-dark text-light" type="button" data-bs-toggle="collapse" aria-expanded="false" :data-bs-target="'#b' + name" @click="loadData" :disabled="!is_processed" :style="!is_processed ? 'cursor: progress;' : ''">
								<div v-if="description === ''" class="container px-1">{{ formatDate(uploaded_at) }}</div>
								<div v-else class="container px-1">
										<div class="row">
											<div class="col my-1">
												{{ description }}
											</div>
											<div class="col my-1">
												<div class="m-0 p-0 text-end">
													<span v-if="is_processed" class="text-end text-muted fst-italic">{{ formatDate(uploaded_at) }}</span>
													<div v-else class="text-end spinner-border align-middle text-light"  role="status" style="width: 1.5rem;height: 1.5rem;">
														<span class="visually-hidden">Loading...</span>
													</div>
												</div>
											</div>
										</div>
								</div>
							</button>
						</div>
						<div class="col-sm-1 mb-1 mt-2">
							<button v-if="is_processed" title="download VQIs" @click="downloadResults" class="btn btn-sm btn-primary float-end mb-1">
								<DownloadIcon size="16" />
							</button>
							<button v-if="is_processed" title="delete VQIs" @click="deleteResults" class="btn btn-sm btn-danger float-end">
								<TrashIcon size="16" />
							</button>
						</div>
					</div>
				</div>
			</h2>
			<div :id="'b' + name" class="acc-body accordion-collapse collapse overflow-scroll" :aria-labelledby="'b' + name" :data-bs-parent="'#b' + name">
				<div class="accordion-body">
					<div v-if="lines.length === 0">Loading...</div>
					<div v-if="lines == null">An error occurred</div>
					<table v-else class="table table-sm table-striped text-center mx-1" style="font-size: .9rem">
						<thead>
						<tr>
							<th class="text-center p-1" scope="col" v-for="el in headers">{{ el }}</th>
						</tr>
						</thead>
						<tbody>
							<tr v-for="line in lines">
								<td v-for="item in line">{{ item }}</td>
							</tr>
						</tbody>
					</table>
					<div class="text-center" v-if="moreThan1000">This table contains more than 1000 rows - to see them all please download the CSV file.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DownloadIcon from "@/components/icons/DownloadIcon";
import TrashIcon from "@/components/icons/TrashIcon";
import {formatDate} from "@/utils";

export default {
	name: "MovieRecord",
	components: {DownloadIcon, TrashIcon},
	props: ['id', 'uploaded_at', 'is_processed', 'name', 'description'],
	data() {
		return {
			lines: [],
			headers: [],
			moreThan1000: false
		}
	},
	methods: {
		formatDate,
		async loadData() {
			if (!this.is_processed) {
				return;
			}
			if (this.lines.length > 0) {
				const {data} = await axios.get(`/vqis/results/${this.name}`);
				if (data.data == null) {
					return;
				}
				if (this.lines !== data.data.lines) {
					this.headers = data.data.headers;
					this.moreThan1000 = data.data.moreThan1000;
					this.lines = data.data.lines;
				}
				return;
			}

			const {data} = await axios.get(`/vqis/results/${this.name}`);
			if (data.data == null) {
				this.lines = null;
				return;
			}
			this.headers = data.data.headers;
			this.lines = data.data.lines;
			this.moreThan1000 = data.data.moreThan1000;
		},
		async downloadResults() {
			const {data} = await axios.get(`/vqis/results/${this.name}?download=true`);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.style = 'display:none';
			const blob = new Blob([data.data.csv], { type: 'text/csv' }),
			url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = (this.description === '' ? this.formatDate(this.uploaded_at).replaceAll(':', '-').replaceAll(' ', '-') : this.description) + '.csv';
			a.click();
			window.URL.revokeObjectURL(url);
		},
		async deleteResults() {
			if (!this.is_processed) {
				return;
			}

			this.$emit('deleted', this.id);

			try {
				await axios.delete(`/vqis/videos/${this.name}`);
			} catch (e) {}
		}
	}
}
</script>

<style scoped>

</style>
