import {createStore} from 'vuex'
import Auth from './auth'

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth: Auth
    }
});
