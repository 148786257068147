<template>
	<main class="vh-100">
		<div class="container-fluid">
			<div class="row" style="display:flex;align-items: center;">
				<div class="col-sm-6 text-black mt-3">
					<div class="px-5 ms-xl-4">
						<a href="#"><img class="mt-xl-4" src="@/assets/images/cropped-logoqoe_crop_square-1.png" width="50" alt="logo"/></a>
						<div class="h1 fw-bold mb-0">AGH Video Quality of Experience (QoE) Team</div>
					</div>
					<div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
						<form @submit.prevent="login">
							<h3 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Sign in</h3>
							<div v-if="message" class="mb-3 fs-5 text-center">
								<div :class="`alert alert-${error ? 'danger' : 'success'}`">{{message}}</div>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input type="text" v-model="username" class="form-control form-control-lg" minlength="5" maxlength="30" required />
									Username
								</label>
							</div>
							<div class="form-outline mb-4">
								<label class="form-label">
									<input type="password" v-model="password" :class="`form-control form-control-lg ${this.message.includes('password') && this.error && 'is-invalid'}`" minlength="6" maxlength="30" required />
									Password
								</label>
							</div>

							<div class="pt-1 mb-4">
								<button class="w-100 btn-lg btn-block" type="submit">Login</button>
							</div>
<!--							<p class="small mb-5 pb-lg-2"><a class="text-muted" href="#">Forgot password?</a></p>-->
							<p>
								Don't have an account?
								<router-link to="/register" class="link-secondary">Register here</router-link>
							</p>
						</form>
					</div>
				</div>
				<div class="col-sm-6 px-0 d-none d-sm-block">
					<img src="@/assets/images/cropped-back-cut.png"
					     alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from "axios";
import router from "@/router";
import '@/assets/styles/login.css'

export default {
	name: "Login",
	data: () => ({
		username: '',
		password: '',
		message: '',
		error: false
	}),
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		}
	},
	beforeMount() {
		this.$store.dispatch('attempt')
			.then(async _ => {
				if (this.auth) {
					await router.push('/dashboard');
				}
			})
			.catch(error => {});

		if (this.$route.query.token != null) {
			const token = this.$route.query.token;

			this.$store.dispatch("activateAccount", {token})
				.then(res => {
					this.error = !res.success;
					this.message = res.message;
				})
				.catch(error => {
					this.message = error?.response.data.message || 'Error';
					this.error = true;
				});
		}
	},
	methods: {
		login() {
			this.$store.dispatch("login", {
				username: this.username,
				password: this.password
			})
				.then(res => {
					this.$router.push('/dashboard');
				})
				.catch(error => {
					this.password = '';
					this.message = error?.response.data.message || 'Error';
					this.error = true;
				});
		}
	}
};
</script>
