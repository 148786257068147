<template>
		<Nav v-if="auth || (currentRouteName !== 'login' && currentRouteName !== 'register')" />
		<router-view />
		<Footer v-if="auth || (currentRouteName !== 'login' && currentRouteName !== 'register')" />
</template>

<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
	components: {Footer, Nav},
	computed: {
		auth() {
			return this.$store.getters.authenticated;
		},
		currentRouteName() {
			return this.$route.path.slice(1)
		}
	},
};
</script>
