import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import VQIs from "@/views/VQIs";
import Dashboard from "@/views/Dashboard";
import PageNotFound from "@/views/PageNotFound";

const routes = [
    { path: '/', component: VQIs },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/dashboard', component: Dashboard },
    { path: '/vqis', component: VQIs },
    { path: '/:pathMatch(.*)*', component: PageNotFound }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
